
import { signOut } from "@/api/auth";
import MSInfoItem from "@/components/setting/MSInfoItem.vue";
import store from "@/store";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MSInfoItem
  }
})
export default class Logout extends Vue {
  get name(): string {
    if (!store.state.student) {
      return "-";
    }
    return store.state.student.data.name;
  }

  get studentId(): string {
    if (!store.state.role) {
      return "";
    }
    return store.state.role.data.loginId ?? "";
  }

  async signOut() {
    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "ログアウト中...");
    try {
      await signOut();
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
    } catch (e) {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      alert(
        "ログアウトに失敗しました。時間を置いてから再度実行してみてください"
      );
      return;
    }
    store.commit("SIGN_OUT");
    this.$router.replace("/login");
  }

  mounted(): void {
    this.signOut();
  }
}
